import React, {useLayoutEffect, useRef, useState, useEffect} from "react";
import {motion} from "framer-motion";
import classNames from "classnames/bind";

import {AgentTaskContainer} from "../../components/agent-task-container";
import {AiPersonaTask} from "../../../models/ai-orchestration";
import {Spinner} from "../../../shared/v2";
import {ObjectiveCard} from "../../components/objective-card";
import {SourcesCard} from "../../components/sources-card";
import {FlowOutputCard} from "../flow-output-card";
import {Connector} from "../connector";
import {useWorkflowContext, useCarouselScrollContext, useWorkflowModalsContext, useRunWorkflowContext, useCarouselConstraintsContext} from "../../../context/workflow-contexts";
import {WorkflowCanvasButton} from "../workflow-canvas-button";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./workflow-canvas.module.scss";

const bStyles = classNames.bind(styles);

const MIN_ZOOM = 0.5;
const MAX_ZOOM = 2;
const ZOOM_SPEED = 0.003;
const PAN_SPEED = 1.5;

export interface WorkflowCanvasProps {
	disabled?: boolean;
	agents?: AiPersonaTask[];
}

export const WorkflowCanvas = ({disabled, agents}: WorkflowCanvasProps) => {
	const {isDarkMode} = useThemeMode();
	const {
		workflow,
		agentTasks,
		setAgentTasks,
		currentSources,
		isLoadingWorkflow,
		currentHistory,
		isOnHistoryTab,
		reportUrl,
		currentHistorySources,
	} = useWorkflowContext();

	const {isWorkflowRunning} = useRunWorkflowContext();

	const {setIsSelectAgentModalOpen, setIsSourcesModalOpen} = useWorkflowModalsContext();
	const {
		draggableEl,
		setDraggableEl,
		constraintEl,
		setConstraintEl,
		constraints: carouselConstraints,
	} = useCarouselConstraintsContext();
	const lastElementRef = useRef<HTMLDivElement>(null);
	const touchStartXRef = useRef<number | null>(null);
	const touchStartYRef = useRef<number | null>(null);
	const isSwipingRef = useRef(false);
	const dragStartRef = useRef<{ x: number; y: number } | null>(null);

	const {x, y, scrollToElement} = useCarouselScrollContext();

	const [zoom, setZoom] = useState(1);
	const [dragging, setDragging] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });

	useLayoutEffect(() => {
		if (agents && lastElementRef.current) {
			scrollToElement(agents[agents.length - 1].id);
		}
	}, [carouselConstraints.left, carouselConstraints.right, currentHistory]);

	// Global swipe prevention
	useEffect(() => {
		const preventSwipe = (e: TouchEvent) => {
			e.preventDefault();
			e.stopPropagation();
		};

		document.addEventListener('touchstart', preventSwipe, { capture: true, passive: false });
		document.addEventListener('touchmove', preventSwipe, { capture: true, passive: false });
		document.addEventListener('touchend', preventSwipe, { capture: true, passive: false });

		const preventNavigation = () => {
			history.pushState(null, '', window.location.href);
		};

		window.addEventListener('popstate', preventNavigation);

		history.pushState(null, '', window.location.href);
		history.pushState(null, '', window.location.href);

		return () => {
			document.removeEventListener('touchstart', preventSwipe, { capture: true });
			document.removeEventListener('touchmove', preventSwipe, { capture: true });
			document.removeEventListener('touchend', preventSwipe, { capture: true });
			window.removeEventListener('popstate', preventNavigation);
		};
	}, []);

	const handleTouchStart = (e: React.TouchEvent) => {
		if (e.touches.length === 1) {
			touchStartXRef.current = e.touches[0].clientX;
			touchStartYRef.current = e.touches[0].clientY;
			isSwipingRef.current = false;
		}
	};

	const handleTouchMove = (e: React.TouchEvent) => {
		if (e.touches.length === 1 && touchStartXRef.current !== null && touchStartYRef.current !== null) {
			const deltaX = e.touches[0].clientX - touchStartXRef.current;
			const deltaY = e.touches[0].clientY - touchStartYRef.current;

			if (Math.abs(deltaX) > Math.abs(deltaY)) {
				isSwipingRef.current = true;
				setPosition(prev => ({
					...prev,
					x: prev.x + deltaX * PAN_SPEED
				}));
			}

			touchStartXRef.current = e.touches[0].clientX;
			touchStartYRef.current = e.touches[0].clientY;
		}
	};

	const handleTouchEnd = () => {
		touchStartXRef.current = null;
		touchStartYRef.current = null;
		isSwipingRef.current = false;
	};

	const handleTaskChange = (agent: AiPersonaTask, value: string) => {
		setAgentTasks(
			agentTasks.map(item => {
				if (item.id === agent.id) {
					return {...item, operation: "UPDATE", task: {taskPrompt: value}};
				}
				return item;
			}),
		);
	};

	const handleRemoveAgent = agentTask => {
		if (agentTask.id.startsWith("NEW")) {
			setAgentTasks(agentTasks.filter(a => a.id !== agentTask.id));
			return;
		}

		agentTask.operation = "DELETE";
		setAgentTasks(agentTasks.map(item => (item.id === agentTask.id ? agentTask : item)));
	};

	const handleAddAgent = () => {
		setIsSelectAgentModalOpen(true);
	};

	const handleIndexChange = (agent: AiPersonaTask, newIndex: number) => {
		setAgentTasks(
			agentTasks.map(item => {
				if (item.id === agent.id) {
					item = {...item, index: newIndex, operation: "UPDATE"};
					return item;
				}
				return item;
			}),
		);
	};

	const handleWheel = (event: React.WheelEvent) => {
		event.preventDefault();
		event.stopPropagation();

		if (event.ctrlKey || event.metaKey) {
			const newZoom = zoom - event.deltaY * ZOOM_SPEED;
			setZoom(Math.min(Math.max(newZoom, MIN_ZOOM), MAX_ZOOM));
		} else {
			setPosition(prev => ({
				x: prev.x - event.deltaX * PAN_SPEED,
				y: prev.y - event.deltaY * PAN_SPEED
			}));
		}
	};

	const handleDragStart = (event, info) => {
		setDragging(true);
		dragStartRef.current = { x: info.point.x, y: info.point.y };
	};

	const handleDrag = (event, info) => {
		if (dragStartRef.current) {
			const deltaX = info.point.x - dragStartRef.current.x;
			const deltaY = info.point.y - dragStartRef.current.y;
			
			setPosition(prev => ({
				x: prev.x + deltaX * PAN_SPEED,
				y: prev.y + deltaY * PAN_SPEED
			}));

			dragStartRef.current = { x: info.point.x, y: info.point.y };
		}
	};

	const handleDragEnd = () => {
		setDragging(false);
		dragStartRef.current = null;
	};

	const renderWorkflow = () => {
		if (isLoadingWorkflow) {
			return (
				<div className={styles.loadingContainer}>
					<Spinner className={styles.spinner} />
				</div>
			);
		}
		if (!workflow) {
			return null;
		}

		return (
			<motion.div
				className={bStyles("carouselContent", {dragging})}
				drag={true}
				dragElastic={0}
				dragMomentum={false}
				dragConstraints={carouselConstraints}
				onDragStart={handleDragStart}
				onDrag={handleDrag}
				onDragEnd={handleDragEnd}
				id="workflow-carousel"
				style={{
					x: position.x,
					y: position.y,
					scale: zoom,
					transformOrigin: "0 0",
					cursor: dragging ? "grabbing" : "grab"
				}}
			>
				<div className={styles.workflowContent}>
					{/*<ObjectiveCard objective={workflow?.description} />*/}
					<div className={styles.carousel} ref={setDraggableEl}>
						{isOnHistoryTab &&
							<SourcesCard
								workflow={workflow}
								openSourcesModal={() => setIsSourcesModalOpen(true)}
								currentSources={currentHistorySources}
								disabled={disabled}
							/>
						}
						{!isOnHistoryTab &&
							<SourcesCard
								workflow={workflow}
								openSourcesModal={() => setIsSourcesModalOpen(true)}
								currentSources={currentSources}
								disabled={disabled}
							/>
						}

						{agents?.map((agent, index) => (
							<AgentTaskContainer
								key={agent.id}
								agentTask={agent}
								onRemove={handleRemoveAgent}
								onChangeTask={value => handleTaskChange(agent, value)}
								onChangeIndex={handleIndexChange}
								disabled={disabled}
								elementRef={index === agents.length - 1 ? lastElementRef : null}
								agentsLength={agents.length}
							/>
						))}

						{!disabled && !isWorkflowRunning &&
							<>
								<Connector />
								<WorkflowCanvasButton
									onClick={handleAddAgent}
									disabled={isWorkflowRunning}
								/>
							</>
						}

						<Connector disabled={disabled} />
						<FlowOutputCard
							reportUrl={isOnHistoryTab ? currentHistory?.reportUrl : reportUrl}
						/>
					</div>
				</div>
			</motion.div>
		);
	};

	return (
		<div
			className={bStyles("canvas", {isDarkMode})}
			ref={setConstraintEl}
			onWheel={handleWheel}
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}
		>
			{renderWorkflow()}
		</div>
	);
};
