import {useMemo} from "react";

export const useSvgImage = (svg: string): HTMLImageElement => {
  const imageEl = useMemo(() => {
    const img = new Image();
    img.src = `data:image/svg+xml;base64,${btoa(svg)}`;
    img.crossOrigin = "anonymous";
    return img;
  }, [svg]);

  return imageEl;
}
