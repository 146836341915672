import React, {ReactElement, ReactNode} from "react";

import styles from "./no-draggable-item.module.scss";
import classNames from "classnames/bind";

const bStyles = classNames.bind(styles);

export interface NoDraggableItemProps {
  children: ReactNode;
  className?: string;
}

export const NoDraggableItem = ({children, className}: NoDraggableItemProps): ReactElement => {
  const stopPropagation = (e: React.PointerEvent) => {
    e.stopPropagation();
  }

  return (
    <div onPointerDownCapture={stopPropagation} className={bStyles("noDraggableItem", className)}>
     {children}
    </div>
  );
}
