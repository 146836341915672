export interface CreateSVGCursorProps {
  size: number;
  fill: string;
  stroke: string;
}

export const createSVGCursor = ({size, fill, stroke}: CreateSVGCursorProps): string => `
  <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 72 72" fill="none">
    <path
      d="M70.4 36C70.4 54.9986 54.9986 70.4 36 70.4C17.0014 70.4 1.6 54.9986 1.6 36C1.6 17.0014 17.0014 1.6 36 1.6C54.9986 1.6 70.4 17.0014 70.4 36ZM36 71.1C55.3852 71.1 71.1 55.3852 71.1 36C71.1 16.6148 55.3852 0.9 36 0.9C16.6148 0.9 0.9 16.6148 0.9 36C0.9 55.3852 16.6148 71.1 36 71.1Z"
      fill="${fill}"
      stroke="${stroke}"
      stroke-width="0.2"
    />
  </svg>
`
