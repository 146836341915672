import {Layer, Line, Stage} from "react-konva";
import {LineConfig} from "konva/lib/shapes/Line";
import {Spinner} from "@/shared/v2";
import {throttle} from "lodash-es";
import classNames from "classnames/bind";
import Konva from "konva";
import React, {ReactElement, useCallback, useEffect, useState} from "react";

import {CanvasImage, Contours, Cursor, SelectedArea} from "./components";
import {useAiActionsContext, useCanvasContext, useCanvasMouseEventMiddleware, useCanvasMouseEventMiddlewareContext, useEditorSettingsContext, useImageHistoryContext} from "../../contexts";
import {useElementSize} from "../../../../hooks/useElementSize";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./image-canvas.module.scss";

const cx = classNames.bind(styles);

export const ImageCanvas = (): ReactElement => {
  const [canvasWrapperEl, setCanvasWrapperEl] = useState<HTMLDivElement | null>(null);
  const [currentLine, setCurrentLine] = useState<LineConfig | null>(null);
  const {brushSize, brushMode} = useEditorSettingsContext();
  const {imageSrc} = useImageHistoryContext();
  const {isUpdatingImage} = useAiActionsContext();
  const {mouseEvents} = useCanvasMouseEventMiddlewareContext();
  const {stageRef, lines, setLines} = useCanvasContext();
  const {isDarkMode} = useThemeMode();
  const isPainting = Boolean(currentLine);
  const size = useElementSize(canvasWrapperEl);

  // Start drawing line
  useCanvasMouseEventMiddleware((e) => {
    const pos = e.target.getStage()?.getPointerPosition();

    if (!pos) {
      return;
    }

    setCurrentLine({
      points: [pos.x, pos.y, pos.x + 1, pos.y + 1],
      strokeWidth: brushSize,
      stroke: "#F00000",
      tension: 1,
      globalCompositeOperation: brushMode === "draw" ? "source-over" : "destination-out",
    });
  }, {
    name: "drawLine",
    deps: [currentLine, brushMode, brushSize],
    event: "onMouseDown",
    order: 100,
  });

  const handleMouseMove = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    const point = stage?.getPointerPosition();

    if (!point || !currentLine || !currentLine.points) {
      return;
    }

    setCurrentLine(prev => ((prev && prev.points) ? {
      ...prev,
      points: Array.from(prev.points).concat([point.x, point.y]),
    } : null));

    stage?.batchDraw();
  }, [currentLine]);

  const throttledHandleMouseMove = useCallback(
    throttle((e: Konva.KonvaEventObject<MouseEvent>) => {
      handleMouseMove(e);
    }, 16),
    [handleMouseMove]
  );

  useEffect(() => {
    return () => {
      throttledHandleMouseMove.cancel();
    };
  }, [throttledHandleMouseMove]);

  // Draw line
  useCanvasMouseEventMiddleware(throttledHandleMouseMove, {
    name: "drawLine",
    deps: [currentLine],
    event: "onMouseMove",
    order: 100,
  });

  // Finish drawing line
  const finishDrawingLine = () => {
    if (!currentLine) {
      return;
    }
    setLines(prev => [...prev, currentLine]);
    setCurrentLine(null);
  };

  useCanvasMouseEventMiddleware(finishDrawingLine, {
    name: "drawLine",
    event: "onMouseUp",
    deps: [currentLine],
    order: 100,
  });

  useCanvasMouseEventMiddleware(finishDrawingLine, {
    name: "drawLine",
    event: "onMouseLeave",
    deps: [currentLine],
    order: 100,
  });

  return (
    <div ref={setCanvasWrapperEl} className={cx("imageWrapper", {disabled: isUpdatingImage, isDarkMode})}>
      <Stage
        className={styles.imageCanvas}
        ref={stageRef}
        width={size.width}
        height={size.height}
        {...mouseEvents}
      >
        <Layer>
          <CanvasImage src={imageSrc} width={size.width} height={size.height} />
          <SelectedArea />
          {currentLine && <Line
            opacity={0.5}
            lineCap="round"
            lineJoin="round"
            tension={1}
            {...currentLine}
          />}
          <Contours
            isPainting={isPainting}
            lines={lines}
            marchingSpeed={isUpdatingImage ? 0 : 100}
          />
          <Cursor size={size} />
        </Layer>
      </Stage>

      {isUpdatingImage && (
        <Spinner className={styles.spinner} />
      )}
    </div>
  )
}
