import React, {ReactElement} from "react";

import {Editor, History, Navigation} from "./components";
import {
  useImageHistoryContext,
  CanvasContextProvider,
  EditorSettingsContextProvider,
  ImageElementContextProvider,
  CanvasMouseEventMiddlewareContextProvider,
  AiActionsContextProvider
} from "./contexts";
import {Portal} from "../../shared/v2";
import {VIEWPORT_PORTAL_NAME} from "../../portals";

import styles from "./image-studio.module.scss";

const ImageStudioContent = (): ReactElement => {
  const {imageSrc} = useImageHistoryContext();

  if (!imageSrc) {
    return <></>;
  }

  return (
    <Portal name={VIEWPORT_PORTAL_NAME}>
      <div className={styles.imageStudioWrapper}>
        <Navigation />
        <div className={styles.content}>
          <History />
          <Editor />
        </div>
      </div>
    </Portal>
  );
}

export const ImageStudio = (): ReactElement => {
  return (
    <CanvasMouseEventMiddlewareContextProvider>
      <EditorSettingsContextProvider>
        <ImageElementContextProvider>
          <CanvasContextProvider>
            <AiActionsContextProvider>
              <ImageStudioContent />
            </AiActionsContextProvider>
          </CanvasContextProvider>
        </ImageElementContextProvider>
      </EditorSettingsContextProvider>
    </CanvasMouseEventMiddlewareContextProvider>
  );
}
