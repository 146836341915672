import React, {ReactElement, useMemo} from "react";
import classNames from "classnames/bind";

import {BrushSizePicker} from "../brush-size-picker";
import {ButtonIcon, Button, Separator} from "../../../../shared/v2";
import {ImageCanvas} from "../image-canvas";
import {KeyboardEventMiddlewareContextProvider} from "../../../../context/keyboard-event-middleware-context";
import {SendArrowChatIcon, SelectAreaToolIcon, ResetBackToolIcon, EnhanceStarPlusToolIcon, RemoveEraserToolIcon} from "../../../../icons";
import {useAiActionsContext, useCanvasContext, useEditorSettingsContext} from "../../contexts";
import {useTask} from "@/hooks/useTask";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {useToastContext} from "@/context/toast-context";
import CommandTextArea from "../../../chat-bubble/command-text-area";

import styles from "./editor.module.scss";

const cx = classNames.bind(styles);

export const Editor = (): ReactElement => {
  const {brushMode, setBrushMode} = useEditorSettingsContext();
  const {lines, getMask, stageRef: {current: stage}, setLines} = useCanvasContext();
  const {updateToast} = useToastContext();
  const [value, setValue] = React.useState("");
  const {isDarkMode} = useThemeMode();
  const {
    enhanceImage,
    changeImage,
    isEnhancingImage,
    isUpdatingImage,
  } = useAiActionsContext();

  const hasMask = useMemo(() => Boolean(stage && getMask().hasMask), [lines.length]);

  const blur = () => {
    const activeElement = document.activeElement as Element;

    if (activeElement instanceof HTMLElement) {
      activeElement.blur();
    }
  }

  const handleChangeImage = async () => {
    if (value.length === 0) {
      updateToast({type: "failure", description: "Please enter a prompt"});
      return;
    }

    if (!hasMask) {
      updateToast({type: "failure", description: "Please select an area to change"});
      return;
    }

    blur();
    await changeImage(value);
    setValue("");
  };

  const handleEnhanceImage = async () => {
    blur();
    if (isUpdatingImage) {
      return;
    }

    await enhanceImage(value);
    setValue("");
  };

  const {run: handleRemoveFromImage, loading: isRemovingFromImage} = useTask(async () => {
    blur();
    await changeImage("");
  });

  return (
    <div className={styles.editorLayout}>
      <div className={cx("imageStudioEditor", {isDarkMode})}>
        <ImageCanvas />

        <div className={styles.controlsWrapper}>
          <form
            className={cx("inputWrapper", {isDarkMode, disabled: isUpdatingImage})}
            onSubmit={(e) => {
              e.preventDefault();
              handleChangeImage();
            }}
          >
            <KeyboardEventMiddlewareContextProvider>
              <CommandTextArea
                value={value}
                disabled={isUpdatingImage}
                textAreaProps={{"aria-label": "chat-message"}}
                placeholder="What would you like to change?"
                onChange={setValue}
                handleSubmit={handleChangeImage}
                focusOnMount={false}
              />
            </KeyboardEventMiddlewareContextProvider>
            <ButtonIcon
              className={styles.sendIcon}
              icon={<SendArrowChatIcon />}
              onClick={handleChangeImage}
            />
          </form>

          <div className={styles.toolkit}>
            <Button
              className={styles.button}
              style="toolkit"
              leftIcon={<SelectAreaToolIcon />}
              active={brushMode === "draw"}
              size="small"
              onClick={() => setBrushMode("draw")}
            >
              Select
            </Button>

            <Button
              className={styles.button}
              style="toolkit"
              leftIcon={<SelectAreaToolIcon />}
              active={brushMode === "erase"}
              size="small"
              onClick={() => setBrushMode("erase")}
            >
              Subtract
            </Button>

            <BrushSizePicker />

            <Button
              className={styles.button}
              style="toolkit"
              leftIcon={<ResetBackToolIcon />}
              size="small"
              disabled={isUpdatingImage}
              onClick={() => setLines([])}
            >
              Reset
            </Button>

            <Separator orientation="vertical" className={styles.separator} />

            <Button
              className={styles.button}
              style="toolkit"
              leftIcon={<EnhanceStarPlusToolIcon />}
              size="small"
              onClick={handleEnhanceImage}
              active={isEnhancingImage}
              disabled={isUpdatingImage}
            >
              Enhance
            </Button>

            <Button
              className={styles.button}
              style="toolkit"
              leftIcon={<RemoveEraserToolIcon />}
              size="small"
              disabled={!hasMask}
              active={isRemovingFromImage}
              onClick={handleRemoveFromImage}
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
