import {useMutation} from "@apollo/client";
import classNames from "classnames/bind";
import React, {ReactElement, useState} from "react";
import TimeAgo from "react-timeago";

import {BinDeleteIcon, ClockTimeTimerDateIcon, DotsIcon, PenEditIcon} from "../../../../icons";
import {Body, ButtonIcon, Caption, Card, Dropdown, Subheader} from "../../../../shared/v2";
import {CHAT_DELETE_CONVERSATION} from "../../../../graphql/mutations/ai-mutations";
import {ChatConversation} from "../../../../models";
import {ConfirmActionModal} from "../../../../shared/v2/modals/confirm-action-modal";
import {GET_CHAT_CONVERSATIONS, GET_CHAT_CONVERSATIONS_WITH_PREVIEW} from "../../../../graphql/queries/ai-models-queries";
import {useNavigate} from "../../../../route";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {EditChatModal} from "../../../components/edit-chat-modal";

import styles from "./conversation-card.module.scss";
import {useInView} from "react-intersection-observer";

const bStyles = classNames.bind(styles);

export interface ConversationCardProps {
	conversation: ChatConversation
}

export const ConversationCard = ({conversation}: ConversationCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [deleteConversation] = useMutation(CHAT_DELETE_CONVERSATION, {refetchQueries: [GET_CHAT_CONVERSATIONS_WITH_PREVIEW, GET_CHAT_CONVERSATIONS]})
	const navigate = useNavigate();

	const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

	const renderArtifacts = () => {
		const images = conversation.preview?.images.filter((({length}) => length > 0)) ?? [];

		if (images.length === 0) {
			return <Body size="xs" className={styles.noArtifacts} color="gray-modern-400">No artifacts have been generated yet.</Body>
		}

		return images.map((image, index) => (
			<div key={`image-${index}`} ref={ref}>
				{inView && <img
					src={image}
					alt={image}
					className={styles.artifact}
				/>}
			</div>
		))
	}

	const handleDelete = () => {
		deleteConversation({
			variables: {
				chatDeleteConversationId: conversation.id
			}
		})
		setIsDeleteModalOpen(false)
	};

	const navigateToConversation = () => {
		navigate(`/workflow/conversation/?canvasId=${conversation.id}`)
	};

	const createdAt = conversation.preview?.createdAt;

	return <>
		<Card
			onClick={navigateToConversation}
			className={bStyles("card", {isDarkMode})}
		>
			<Dropdown
				className={styles.options}
				popupClassName={styles.dropdownPopup}
				items={[
					{
						label: "Rename",
						icon: <PenEditIcon />,
						onClick: () => setIsEditModalOpen(true)

					},
					{
						label: "Delete",
						color: "danger",
						icon: <BinDeleteIcon />,
						onClick: () => setIsDeleteModalOpen(true)
					},
				]}
				position="right-start"
				space={4}
				trigger={<ButtonIcon icon={<DotsIcon />} />}
			/>
			<div className={styles.content}>
				<div className={styles.headerMessageContainer}>
					<div className={styles.topContainer}>
						<div className={styles.createdDate}>
							<ClockTimeTimerDateIcon className={styles.timeIcon} />
							<Caption className={styles.createdAt} color="gray-modern-500">
								{createdAt && <TimeAgo date={createdAt} />}
							</Caption>
						</div>
						<div className={styles.headerSection}>
							<Subheader className={styles.name} color="gray-modern-900" type="medium">{conversation.name}</Subheader>
							<Caption color="gray-modern-500" className={bStyles("creator", {isDarkMode})}>
                by {conversation.preview?.creator.firstName} {conversation.preview?.creator.lastName}
							</Caption>
						</div>
					</div>

					<Body size="xs" color="text-secondary" className={styles.message}>
						{conversation.preview?.content}
					</Body>

				</div>

				<div className={bStyles("artifactsContainer", {"empty": !conversation.preview?.images.length})}>
					{renderArtifacts()}
				</div>
			</div>
		</Card>

		<ConfirmActionModal
			isOpen={isDeleteModalOpen}
			onClose={() => setIsDeleteModalOpen(false)}
			onConfirm={handleDelete}
			title="Delete conversation"
			description="Are you sure you want to delete this conversation? This action cannot be undone."
			confirmText="Delete"
		/>

		<EditChatModal
			isOpen={isEditModalOpen}
			onClose={() => setIsEditModalOpen(false)}
			conversation={conversation}
		/>
	</>
}
