import {Image} from "react-konva";
import {throttle} from "lodash-es";
import Konva from "konva";
import React, {ReactElement, useEffect, useMemo, useRef, useState} from "react";

import {createSVGCursor} from "./helpers";
import {useCanvasMouseEventMiddleware} from "../../../../contexts/canvas-mouse-event-middleware";
import {useEditorSettingsContext, useImageHistoryContext} from "../../../../contexts";
import {useSvgImage} from "../../../../../../hooks";

export interface CursorProps {
  size: {width: number, height: number};
}

export const Cursor = ({size}: CursorProps): ReactElement => {
  const cursorRef = useRef<Konva.Image | null>(null);
  const {brushSize} = useEditorSettingsContext();
  const {imageSrc} = useImageHistoryContext();
  const cursorDark = useSvgImage(createSVGCursor({size: brushSize, fill: "black", stroke: "#EAEAEA"}));
  const cursorLight = useSvgImage(createSVGCursor({size: brushSize, fill: "#EAEAEA", stroke: "black"}));
  const [lastCursorEl, setLastCursorEl] = useState<HTMLImageElement>(cursorLight);

  useEffect(() => {
    if (!cursorRef.current) return;

    const stage = cursorRef.current.getStage();
    if (!stage) return;

    stage.container().style.cursor = "none";
  }, [cursorRef.current]);

  const cachedCanvas = useMemo(() => {
    const stage = cursorRef.current?.getStage();

    if (!stage || stage.width() === 0 || stage.height() === 0) {
      return null;
    }

    return stage.toCanvas();

  }, [cursorRef.current, size, imageSrc]);

  const handleMouseMove = throttle((e: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    const pointerPosition = stage?.getPointerPosition();
    if (!pointerPosition || !cursorRef.current) return;

    // Update cursor position
    const cursor = cursorRef.current;
    cursor.position(pointerPosition);
    cursor.getLayer()?.batchDraw();

    // Adjust cursor color based on background brightness
    const ctx = cachedCanvas?.getContext("2d");
    if (ctx) {
      const { x, y } = pointerPosition;
      const pixelData = ctx.getImageData(Math.floor(x), Math.floor(y), 1, 1).data;
      const brightness = (pixelData[0] + pixelData[1] + pixelData[2]) / 3;

      const newCursorEl = brightness > 128 ? cursorDark : cursorLight;
      if (newCursorEl !== lastCursorEl) {
        setLastCursorEl(newCursorEl);
        cursor.image(newCursorEl);
        cursor.getLayer()?.batchDraw();
      }
    }
  }, 16);

  useCanvasMouseEventMiddleware(handleMouseMove, {
    name: "cursor",
    event: "onMouseMove",
    deps: [
      cachedCanvas,
      cursorDark,
      cursorLight,
      cursorRef,
      lastCursorEl,
      setLastCursorEl,
    ],
    order: 1,
  });

  useCanvasMouseEventMiddleware((e) => {
    const stage = e.target.getStage();
    if (!stage) return;

    const pointerPosition = stage.getPointerPosition();
    if (!pointerPosition) return;

    const cursor = cursorRef.current;
    if (!cursor) return;

    cursor.visible(true);
    cursor.position(pointerPosition);
    cursor.getLayer()?.batchDraw();
  }, {
    name: "cursor",
    event: "onMouseEnter",
    deps: [
      cachedCanvas,
      cursorRef,
    ],
    order: 1,
  });

  useCanvasMouseEventMiddleware((e) => {
    const stage = e.target.getStage();
    if (!stage) return;

    const pointerPosition = stage.getPointerPosition();
    if (!pointerPosition) return;

    const cursor = cursorRef.current;
    if (!cursor) return;

    cursor.visible(false);
    cursor.getLayer()?.batchDraw();
  }, {
    name: "cursor",
    event: "onMouseLeave",
    deps: [
      cachedCanvas,
      cursorRef,
    ],
    order: 1,
  });

  return (
    <Image
      height={brushSize}
      image={lastCursorEl}
      offsetX={brushSize / 2}
      offsetY={brushSize / 2}
      ref={cursorRef}
      visible={false}
      width={brushSize}
    />
  );
}
