import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {BrushSizeDotIcon} from "../../../../icons";
import {Button, Caption, Slider} from "../../../../shared/v2";
import {Popup} from "../../../../shared/v2/popup";
import {useEditorSettingsContext} from "../../contexts";
import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./brush-size-picker.module.scss";

const cx = classNames.bind(styles);

export const BrushSizePicker = (): ReactElement => {
  const {isDarkMode} = useThemeMode();
  const {brushSize, setBrushSize} = useEditorSettingsContext();

  const trigger = (open) => (
    <Button
      className={styles.button}
      style="toolkit"
      leftIcon={<BrushSizeDotIcon />}
      active={open}
      size="small"
    >
      {brushSize}px
    </Button>
  )

  return (
    <Popup
      space={20}
      trigger={trigger}
      arrow
      popupClassName={styles.brushSizePicker}
      className={cx("wrapper", {isDarkMode})}
      position="top"
    >
      <div className={styles.captions}>
        <div data-popper-arrow className={styles.arrow} />
        <Caption className={styles.caption} type="medium">
          Brush size
        </Caption>

        <Caption className={styles.caption} type="medium">
          {brushSize}px
        </Caption>
      </div>

      <Slider
        value={[brushSize]}
        onValueChange={([value]) => setBrushSize(value)}
        max={160}
        step={1}
        min={4}
      />
    </Popup>
  );
}
