import React, {ReactElement, ReactNode, createContext, useCallback} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {useNavigate} from "react-router";

import {useSearchParams} from "../../route";
import {ChatConversation, Persona} from "../../models";
import {GET_CHAT_CONVERSATION, SYSTEM_AGENTS} from "../../graphql/queries/ai-models-queries";
import {CHAT_CREATE_CONVERSATION, CHAT_UPDATE_CONVERSATION} from "../../graphql/mutations/ai-mutations";
import {useWorkspaceContext} from "../workspace-context";
import {ChatConversationMessage, SystemAgent} from "../../models/ai-model";
import {useToastContext} from "../../context/toast-context";

export interface ChatUpdateConversationChanges {
	name?: string;
	trainingSetIds?: string[];
	aiPersonaId?: string | null;
	surveyIds?: string[] | null;
}

export type UpdateChatConversation = (changes: ChatUpdateConversationChanges) => Promise<ChatConversation>;

export interface SendQuestionOptions {
	conversationInput?: CreateChatConversationInput;
	sendQuestionInput?: ChatSendQestionInput;
	createNewConversation?: boolean;
	systemAgentId?: string;
	messageId?: string;
}

export interface ChatConversationContextValue {
	conversation?: ChatConversation;
	refetch: () => Promise<ChatConversation>;
	createConversation: (input?: CreateChatConversationInput) => Promise<ChatConversation>;
	updateConversation: UpdateChatConversation;
	isInitialLoading?: boolean;
	isLoading?: boolean;
	isUpdating?: boolean;
	isCreating?: boolean;
	systemAgents?: SystemAgent[];
}

export const ChatConversationContext = createContext<ChatConversationContextValue | undefined>(undefined);

interface ChatCreateConversationResponse {
	chatCreateConversation: ChatConversation;
}

export interface ChatUpdateConversationResponse {
	chatUpdateConversation: ChatConversation;
}

export interface ChatSendQuestionResponse {
	chatSendQuestion: ChatConversationMessage;
}

export interface CreateChatConversationInput {
	aiPersonaId?: string;
	trainingSetIds?: string[];
	surveyIds?: string[];
	isPreview?: boolean;
	name?: string;
}

export interface ChatSendQestionInput {
	image?: string;
	persona?: Persona;
	instructions?: string;
}

export const ChatConversationContextProvider = ({children}: {children: ReactNode}): ReactElement => {
	const {canvasId, personaId, trainingSetIds, surveyIds} = useSearchParams();
	const {updateToast} = useToastContext();
	const navigate = useNavigate();

	const {
		data: {chatConversation} = {},
		networkStatus,
		refetch,
	} = useQuery<{chatConversation: ChatConversation}>(GET_CHAT_CONVERSATION, {
		variables: {chatConversationId: canvasId},
		skip: !canvasId,
		fetchPolicy: "cache-and-network",
		onError: () => {
			navigate("/");
			updateToast({
				type: "failure",
				description: "There was a problem. Unable to fetch conversation.",
			});
		},
	});

	const handleRefetch = useCallback(async () => {
		const {
			data: {chatConversation: result},
		} = await refetch();

		return result;
	}, [refetch]);

	const conversation = chatConversation?.id === canvasId ? chatConversation : undefined;

	const {data: systemAgentsData} = useQuery(SYSTEM_AGENTS, {
		fetchPolicy: "cache-first",
	});

	const [chatCreateConversation, {loading: isCreating}] =
		useMutation<ChatCreateConversationResponse>(CHAT_CREATE_CONVERSATION);
	const [chatUpdateConversation, {loading: isUpdating}] =
		useMutation<ChatUpdateConversationResponse>(CHAT_UPDATE_CONVERSATION);

	const isInitialLoading = networkStatus === 1 || (networkStatus === 2 && !conversation);

	// on refetch, set refetching to true
	const isLoading = networkStatus === 4 || isInitialLoading || isCreating || isUpdating;

	const {workspace: {id: workspaceId} = {}} = useWorkspaceContext();

	const serializeInput = (input: CreateChatConversationInput | undefined): CreateChatConversationInput => {
		if (!input) {
			return {};
		}

		return Object.entries(input).reduce((result, [key, value]) => {
			if (value !== null && value !== undefined && !(Array.isArray(value) && value.length === 0)) {
				result[key] = value;
			}
			return result;
		}, {} as CreateChatConversationInput) as CreateChatConversationInput;
	}

	const createConversation = useCallback(
		async (input?: CreateChatConversationInput): Promise<ChatConversation> => {
			const {data: chatCreateConversationData} = await chatCreateConversation({
				variables: {
					workspaceId,
					name: "Untitled chat",
					trainingSetIds,
					aiPersonaId: personaId,
					surveyIds,
					...serializeInput(input),
				},
				onError: () => {
					updateToast({
						type: "failure",
						description: "There was a problem. Unable to create conversation.",
					});
				},
			});

			if (!chatCreateConversationData) {
				throw new Error("Failed to create conversation");
			}

			const createdConversation = chatCreateConversationData.chatCreateConversation;

			let searchParams = new URLSearchParams();
			searchParams.set("canvasId", createdConversation.id);

			if (input?.isPreview) {
				searchParams = new URLSearchParams(location.search);
				searchParams.set("canvasId", createdConversation.id);
			}
			navigate({
				search: searchParams.toString(),
			});

			return chatCreateConversationData.chatCreateConversation;
		},
		[workspaceId, trainingSetIds?.join(""), personaId, surveyIds?.join("")],
	);

	const updateConversation: UpdateChatConversation = useCallback(
		async (changes): Promise<ChatConversation> => {
			if (isLoading) {
				updateToast({
					type: "failure",
					description: "There was a problem. Changes were not saved.",
				});
				throw new Error("Refetching conversation");
			}

			if (Object.keys(changes).length === 0) {
				throw new Error("No changes provided");
			}

			if (!chatConversation) {
				throw new Error("No conversation");
			}

			const {data: chatUpdateConversationData} = await chatUpdateConversation({
				variables: {
					conversationId: chatConversation.id,
					changes,
				},
				refetchQueries: [GET_CHAT_CONVERSATION],
				onError: () => {
					updateToast({
						type: "failure",
						description: "There was a problem. Changes were not saved.",
					});
				},
			});

			if (!chatUpdateConversationData) {
				throw new Error("Failed to update conversation");
			}

			return chatUpdateConversationData.chatUpdateConversation;
		},
		[updateToast, chatConversation?.id, isLoading],
	);

	return (
		<ChatConversationContext.Provider
			value={{
				conversation,
				refetch: handleRefetch,
				updateConversation,
				createConversation,
				isInitialLoading,
				isLoading,
				isUpdating,
				isCreating,
				systemAgents: systemAgentsData?.systemAgents,
			}}
		>
			{children}
		</ChatConversationContext.Provider>
	);
};

export const useChatConversationContext = (): ChatConversationContextValue => {
	const context = React.useContext(ChatConversationContext);

	if (context === undefined) {
		throw new Error("useChatConversationContext must be used within a ChatConversationContextProvider");
	}

	return context;
};
