import {ReactElement, useRef, useState} from "react";
import {useNavigate, useBlocker, Location} from "react-router";
import {useBeforeUnload} from "react-router-dom";

export interface ConfirmationBlockerProps {
  shouldBlock: boolean;
  renderPrompt: (props: {proceed: () => void; cancel: () => void, isOpen: boolean;}) => ReactElement;
  fallback?: () => void;
}

export function useConfirmationBlocker({shouldBlock, renderPrompt, fallback}: ConfirmationBlockerProps) {
	const navigate = useNavigate();
	const isActiveRef = useRef(true);
	const lastTrxRef = useRef<{
		currentLocation: Location
		nextLocation: Location;
	} | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useBlocker((trx) => {
		const isChangingRoute = trx.currentLocation.pathname !== trx.nextLocation.pathname;

		if (shouldBlock && isActiveRef.current && isChangingRoute) {
			lastTrxRef.current = {
				currentLocation: trx.currentLocation,
				nextLocation: trx.nextLocation,
			};
			setIsModalOpen(true);
			return true;
		}

		return false;
	});

  useBeforeUnload(() => {
    if (shouldBlock && isActiveRef.current) {
      fallback?.();
      return "data will get lost";
    }
  })

	const proceed = () => {
		isActiveRef.current = false;
		setIsModalOpen(false);
		const trx = lastTrxRef.current;

		if (trx) {
			const {pathname, search, hash, state} = trx.nextLocation;
			navigate(pathname + search + hash, {state});
		}
	};

	const cancel = () => {
		setIsModalOpen(false);
	};

	return renderPrompt({proceed, cancel, isOpen: isModalOpen});
}
